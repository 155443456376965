<template>
	<!-- <div class="home">

    <div class="container">
			<div class="row my-5">
				<div class="col-md-4">
					<img src="@/assets/logo.png" class="brand py-4">
				</div>
				<div class="col-md-8 mt-5 text-right">
					<router-link class="active text-dark" to="/auth/login"><strong>Log In</strong></router-link> &nbsp;&nbsp;
      		<router-link class="text-dark" to="/auth/register"><strong>Register</strong></router-link> &nbsp;&nbsp;
      		<router-link class="text-dark" to="/faq"><strong>FAQ</strong></router-link>
				</div>
			</div>
      <div class="row align-items-center">
        <div class="col-lg-12 text-center">
					<img class="img rounded mb-4 mb-lg-0" src="@/assets/frame.png" alt="..." />
				</div>
      </div>

      <div class="row gx-4 gx-lg-5">
        <div class="col-md-4 mb-5">
					<p class="title-footer mb-0">Instruction</p>
					<h3 class="title-footer-sub">Register Step :</h3>
					<p class="description">1. Register first to get access.</p>
					<p class="description">2. Check your email inbox for account information.</p>
					<p class="description">3. Login to portal Visitor Management System.</p>
        </div>
        <div class="col-md-4 mb-5">
					<p class="title-footer mb-0">About</p>
					<h3 class="title-footer-sub">Contact Us :</h3>
					<p class="description">Email   : support@lintasarta.co.id</p>
					<p class="description">Hotline : 14052</p>
        </div>
        <div class="col-md-4 mb-5">
					<p class="title-footer mb-0">Help</p>
					<h3 class="title-footer-sub">Guidance :</h3>
					<p class="description link">
						<router-link to="/video" target="_blank">1. Tutorial Video.</router-link>
					</p>
					<p class="description link"><a target="_blank" href="https://dcvisit.lintasarta.net/UserManual.pdf">2. Manual PDF.</a></p>
        </div>
      </div>

    </div>

	</div> -->

	<div class="home cover-container d-flex w-100 mx-auto flex-column">

  <header class="mb-auto">
    <div class="row justify-content-center">
		<div class="mx-1 col-xs-1 col-sm-1 col-md-5 col-lg-5 col-xl-5 px-4">
			<a href="/">
				<img src="@/assets/logo-new.png" class="brand img-responsive ms-auto justify-content-sm-start float-sm-start px-3 py-3">
			</a>	
		</div>  
		<div class="mx-1 col-xs-1 col-sm-1 col-md-5 col-lg-5 col-xl-5 px-4">
		  <nav class="nav nav-masthead ms-auto justify-content-sm-start justify-content-md-center float-sm-start float-md-end text-uppercase nav-title">
			<!-- <a class="nav-link text-dark" aria-current="page" router-link="/auth/login">Login</a>
			<a class="nav-link text-dark" router-link="/auth/register">Register</a>
			<a class="nav-link text-dark" router-link="/faq">FAQ</a> -->
				<router-link class="nav-link text-dark" to="/auth/login"><strong>Log In</strong></router-link> &nbsp;&nbsp;
      	<router-link class="nav-link text-dark" to="/auth/register"><strong>Register</strong></router-link> &nbsp;&nbsp;
      	<router-link class="nav-link text-dark" to="/faq"><strong>FAQ</strong></router-link>
		  </nav>
		</div>  
    </div>
  </header>

  <main class="px-3 ml-2">
	<div class="container">
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<img src="@/assets/frame01.png" class="mx-auto d-block scene img-fluid">
		</div>
	</div>	
  </main>

  <footer class="mt-auto mb-auto mx-5">
	<div class="row justify-content-around">
		
		<div class="mx-5 col-xs-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 px-4">
			<p class="title-footer mb-0">Instruction</p>
			<p class="title-footer-sub">Register Step</p>
		
			<ul>
				<li>1. Register first to get access</li>
				<li>2. Check your email inbox for account information</li>
				<li>3. Login to portal Visitor Management System</li>
			</ul>
		</div>
		<div class="mx-1 col-xs-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 px-4">
			<p class="title-footer mb-0">About</p>
			<p class="title-footer-sub">Contact Us</p>
			<ul><li>Email : support@lintasarta.co.id</li><li>Hotline : 14052</li></ul>
		</div>
		<div class="mx-1 col-xs-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 px-4">
			<p class="title-footer mb-0">Help</p>
			<p class="title-footer-sub">Guidance</p>
			<ul>
				<li>
					<router-link to="/video" target="_blank">1. Tutorial Video</router-link>.
				</li>
				<li><a class="link" href="https://dcvisit.lintasarta.net/UserManual.pdf">2. Manual PDF</a>.</li>
			</ul>
		</div>
	</div>	
  </footer>
</div>

</template>

<script>
export default {
	methods: {
		pdfOpen() {
			window.location.href = '@/assets/files/UserManual.pdf'
		}
	},
}
</script>



<style scoped lang="css">
	* { 
		font-family: 'Montserrat';
		overflow-x:hidden;
		background: #fff;
	}
	.home {
		height: 100vh;
	}
  .nav-title {
		font-size: 0.8rem;
		font-weight: bold;
		padding: 1.5rem;
	}	   
	.brand {
		width: 30%;
		margin-left: 1rem !important;
	}
	.banner {
		position:absolute;
		width:15em;
		height:50em;
	}
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
	.title-footer {
		font-size: 14px;
		text-align:left;
	}
	.title-footer-sub {
		font-size: 1.4rem;
		text-align:left;
		font-weight: bolder;
		color: #2D94D1;
		margin-bottom:0.2rem;
	}
	.description {
		font-size: 12px;
		text-align: left;
	}
	ol {
		font-size:12px;
		margin-left:-16px;
	} 	
	ul {
		font-size:12px;
		margin-left:-30px;
		list-style-type:none;
	} 
	.link{
		text-decoration:none;
		color:black;
	}	
	.link :active, .link :hover {
		color:#2D94D1;
	}
	
  @media (min-width: 320px) and (max-width: 425px) {
		.brand {
			margin-left: 1.6rem !important;
			width:35%;
			margin-bottom:-2.5rem;
		} 
		.nav-title {
			margin-right:1rem;
		}
	}	
	@media (min-width: 768px) and (max-width: 1023px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
	  .nav-title {
			margin-right:-4rem;
		}
  }
	@media (min-width: 1024px)  {
    .nav-title {
			margin-right:6rem;
		}
  }
</style>